<template>
  <div class="c-session-block__participant-names-wrapper">
    <template v-if="compact">
      <strong>{{ participant.name }}</strong>, {{ club.name }}
      <span class="c-session-block__meta-info">{{ club.name }}</span>
    </template>
    <template v-else>
      <template v-for="name in getNames()">
        <div :key="name" class="name c-session-block__participant-name">{{name}}</div>
      </template>
      <div class="c-session-block__participant-club-name">{{club.name}}</div>
    </template>
  </div>
</template>

<script>
  import participantLib from 'client/lib/participant.js';

  import base from './participantBase.vue';

  export default {
    extends: base,
    name: "participant-group",
    computed: {
      discipline: function () {
        return this.$store.state.eventDiscipline.discipline;
      },
    },
    methods: {
      getNames: function() {
        return participantLib.getParticipantNames(this.participation)
      }
    }
  };
</script>

<style scoped>
</style>
