<template>
  <div></div>
</template>

<script>
  import _member from 'client/lib/member'
  import _participant from 'client/lib/participant'

  export default {
    name: "participant-clubMember",
    props: ['participation', 'compact'],
    data: function() {
      return {
        participant: null,
        club: null,
        clubName: '',
      }
    },
    watch: {
      participation: {
        immediate: true,
        handler: function() {
          this.init()
        },
      },
    },
    methods: {
      init: function() {
        this.participant = _participant.getParticipant(this.participation)
        this.club = _participant.getClub(this.participant)
        this.clubName = _participant.getClubName(this.participation, this.participant)
      },
      getMemberName: function() {
        return _member.getName(this.participant)
      },
    },
  };
</script>

<style scoped>
</style>
