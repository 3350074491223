<template>
  <div class="c-scores" v-if="calculationType">
    <template v-for="(group, group_i) in getCalculationGroups()">
      <div :key="group.key" v-if="!group.hidden || mode === 'super'">
        <div v-if="group.roster === 'horizontal'"
             class="c-scores__table"
             :class="{'c-scores__table-total': group.total.enabled && ! group.elements.length }">

          <div v-if="group.total.enabled" class="c-scores__table-header"
               :class="{'js-scores-judge': group.total.enabled && ! group.elements.length }">
            <div v-if="getGroupJudgePositions(group.key).length > 0"
                class="c-scores__judge-col js-scores-judge">
              <div v-for="(position, index) in getGroupJudgePositions(group.key)"
                   class="c-scores__judge-header" :key="group.key + '-header-' + position">
                {{$t('score.'+group.key).substr(0, 1).toUpperCase() + (index+1)}}
              </div>
              <div v-if="group.addRef"
                   class="c-scores__judge-header" :key="group.key + '-header-' + group.addRef.position">
                {{ $t('score.reference').substr(0, 1).toUpperCase() }}
              </div>
            </div>
            <div class="c-scores__total-col">
              <span v-if="group.total.enabled && (group.elements.length === 0)">
                {{$t('score.' + group.key)}}
              </span>
            </div>
          </div>

          <div v-if="group.judgeTotal.enabled && group.multipage"
               class="c-scores__table-body c-scores__table-body--total-enabled">
            <div class="c-scores__judge-col js-scores-judge">
              <scoreValue v-for="position in getGroupJudgePositions(group.key)" :key="position"
                          :value="getValueItem({leg: activePass, group: group.key, element: 'total', position: position, index: -1}, group.judgeTotal.valueType)"
                          @selectValue="selectValue($event)" @unselectValue="unselectValue($event)"/>
              <scoreValue v-if="group.addRef"
                          :value="getValueItem({leg: activePass, group: group.addRef.key, element: 'total', position: group.addRef.position, index: -1}, group.judgeTotal.valueType)"
                          @selectValue="selectValue($event)" @unselectValue="unselectValue($event)"/>
            </div>
            <div class="c-scores__total-col">
              <scoreValue v-if="group.total.enabled" class="wide2"
                          :value="getValueItem({leg: activePass, group: group.key, element: 'total', position: -1, index: -1}, group.total.valueType)"/>
            </div>
          </div>

          <div v-for="(element, el_i) in group.elements" :key="group.key + '|' + element.key"
               class="c-scores__table-body">
            <div class="c-scores__header-col">
              {{$t('score.'+element.key)}}
            </div>
            <div class="c-scores__judge-col js-scores-judge">
              <scoreValue v-for="(position, p_i) in getGroupJudgePositions(group.key)" :key="position"
                :editing="currentLabel === group.key + '|'  + element.key + '|' + position + '|0'" :editInput="editInput"
                :value="getValueItem({leg: activePass, group: group.key, element: element.key, position: position, index: 0}, element.valueType)"
                :tabIndex="10 + p_i + 10*el_i + 1000*group_i"
                @selectValue="selectValue($event)" @unselectValue="unselectValue($event)"/>

              <scoreValue v-if="group.addRef"
                          :editing="currentLabel === group.addRef.key + '|'  + element.key + '|' + group.addRef.position + '|0'" :editInput="editInput"
                          :value="getValueItem({leg: activePass, group: group.addRef.key, element: element.key, position: group.addRef.position, index: 0}, element.valueType)"
                          :tabIndex="10 + 9 + 10*el_i + 1000*group_i"
                          @selectValue="selectValue($event)" @unselectValue="unselectValue($event)"/>
            </div>
            <div class="c-scores__total-col">
              <scoreValue v-if="element.elementTotal.enabled" class="wide2"
                :value="getValueItem({leg: activePass, group: group.key, element: element.key, position: -1, index: -1}, element.elementTotal.valueType)"/>
              <scoreValue v-if="group.total.enabled && ! element.elementTotal.enabled && ! group.judgeTotal.enabled && el_i === 0"
                          class="wide2" :value="getValueItem({leg: activePass, group: group.key, element: 'total', position: -1, index: -1}, group.total.valueType)"/>
            </div>
          </div>

          <div v-if="group.judgeTotal.enabled && ! group.multipage"
               class="c-scores__table-body c-scores__table-body--total-enabled">
            <div class="c-scores__judge-col js-scores-judge">
              <scoreValue v-for="position in getGroupJudgePositions(group.key)" :key="position"
                :value="getValueItem({leg: activePass, group: group.key, element: 'total', position: position, index: -1}, group.judgeTotal.valueType)"
                @selectValue="selectValue($event)" @unselectValue="unselectValue($event)"/>
              <scoreValue v-if="group.addRef"
                          :value="getValueItem({leg: activePass, group: group.addRef.key, element: 'total', position: group.addRef.position, index: -1}, group.judgeTotal.valueType)"
                          @selectValue="selectValue($event)" @unselectValue="unselectValue($event)"/>
            </div>
            <div class="c-scores__total-col">
              <scoreValue v-if="group.total.enabled" class="wide2"
                          :value="getValueItem({leg: activePass, group: group.key, element: 'total', position: -1, index: -1}, group.total.valueType)"/>
            </div>
          </div>

          <div v-if="group.total.enabled && (group.elements.length === 0)"
               class="c-scores__table-total-value">
            <div class="c-scores__total-col">
              <scoreValue
                :value="getValueItem({leg: activePass, group: group.key, element: 'total', position: -1, index: -1}, group.total.valueType)"
                class="wide2"/>
            </div>
          </div>
        </div>

        <div v-if="group.roster === 'vertical'"  class="c-scores__table">
          <div class="c-scores__table-header">
              <div class="c-scores__judge-col js-scores-judge">

                <template v-for="(element,index) in group.elements">
                  <template v-if="0 === index || group.rosterLines !== 'separate'">
                    <template v-for="v_index in getIndexRange(element.count)">
                      <div :key="element.key + '|' + v_index"
                           v-if="elementIndexEnabled(v_index, element.valueType)" class="c-scores__judge-header">
                        {{element.count > 1 ? (element.countLabels ? element.countLabels[v_index]
                          : $t('score.'+element.key).substr(0,1).toUpperCase() + (v_index+1))
                          : $t('score.'+element.key).substr(0,3).toUpperCase()}}
                    </div>
                    </template>
                  </template>
                </template>
              </div>
              <div v-if="group.judgeTotal.enabled || group.total.enabled" class="c-scores__total-col">
                <div class="c-scores__judge-header">T</div>
              </div>
          </div>
          <div>
            <template v-if="group.rosterLines === 'separate'">
              <template v-for="(position, p_i) in getGroupJudgePositions(group.key)">
                <div v-for="(element, e_index) in group.elements" :key="position + '|' + element.key"
                     class="c-scores__table-body">
                  <div class="c-scores__header-col">
                    {{$t('score.'+element.key)}}
                  </div>
                  <div class="c-scores__judge-col js-scores-judge">
                    <template v-for="v_index in getIndexRange(element.count)">
                      <scoreValue :key="v_index" v-if="elementIndexEnabled(v_index, element.valueType)"
                        :editing="currentLabel === group.key + '|'  + element.key + '|' + position + '|' + v_index" :editInput="editInput"
                        :value="getValueItem({leg: activePass, group: group.key, element: element.key, position: position, index: v_index}, element.valueType)"
                        :tabIndex="10 + v_index + 10*el_i + 100*p_i + 1000*group_i"
                        @selectValue="selectValue($event)" @unselectValue="unselectValue($event)"/>
                    </template>
                  </div>
                  <div class="c-scores__total-col">
                    <scoreValue v-if="group.judgeTotal.enabled && e_index === 0"
                      :value="getValueItem({leg: activePass, group: group.key, element: 'total', position: position, index: -1}, group.judgeTotal.valueType)"/>
                  </div>
                </div>
                <div class="c-scores__table-body c-scores__table-body--total-enabled" :key="position + '|total'">
                  <div class="c-scores__judge-col js-scores-judge">
                    <template v-for="element in group.elements">
                      <template v-if="element.elementTotal.enabled">
                        <template v-for="v_index in getIndexRange(element.count)">
                          <scoreValue :key="element.key + '|' + v_index"
                            v-if="elementIndexEnabled(v_index, element.valueType)"
                            :value="getValueItem({leg: activePass, group: group.key, element: element.key, position: -1, index: v_index}, element.elementTotal.valueType)" />
                        </template>
                      </template>
                    </template>
                  </div>
                  <div class="c-scores__total-col">
                    <scoreValue v-if="group.total.enabled"
                      :value="getValueItem({leg: activePass, group: group.key, element: 'total', position: -1, index: -1}, group.total.valueType)" />
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div v-for="(position, p_i) in getGroupJudgePositions(group.key)"
                   :key="position" class="c-scores__table-body">
                <div class="c-scores__header-col">
                  {{$t('score.'+group.key) + ' ' + (p_i+1)}}
                  <!--{{$t('score.'+group.key).substr(0, 1).toUpperCase() + (p_i+1)}}-->
                </div>
                <div class="c-scores__judge-col js-scores-judge">
                  <template v-for="(element, el_i) in group.elements">
                    <template v-for="v_index in getIndexRange(element.count)">
                    <scoreValue :key="element.key + '|' + v_index"
                      v-if="elementIndexEnabled(v_index, element.valueType)"
                      :editing="currentLabel === group.key + '|'  + element.key + '|' + position + '|' + v_index" :editInput="editInput"
                      :value="getValueItem({leg: activePass, group: group.key, element: element.key, position: position, index: v_index}, element.valueType)"
                      :tabIndex="10 + v_index + 10*el_i + 100*p_i + 1000*group_i"
                      @selectValue="selectValue($event)" @unselectValue="unselectValue($event)"/>
                    </template>
                  </template>
                </div>
                <div class="c-scores__total-col">
                  <scoreValue v-if="group.judgeTotal.enabled"
                    :value="getValueItem({leg: activePass, group: group.key, element: 'total', position: position, index: -1}, group.judgeTotal.valueType)" />
                </div>
              </div>
              <div class="c-scores__table-body c-scores__table-body--total-enabled">
                <div class="c-scores__judge-col js-scores-judge">
                  <template v-for="element in group.elements">
                    <template v-if="element.elementTotal.enabled">
                      <template v-for="v_index in getIndexRange(element.count)">
                        <scoreValue :key="element.key + '|' + v_index"
                                    v-if="elementIndexEnabled(v_index, element.valueType)"
                                    :value="getValueItem({leg: activePass, group: group.key, element: element.key, position: -1, index: v_index}, element.elementTotal.valueType)"
                                    @selectValue="selectValue($event)" @unselectValue="unselectValue($event)"/>
                      </template>
                    </template>
                  </template>
                </div>
                <div class="c-scores__total-col">
                  <scoreValue v-if="group.total.enabled"
                    :value="getValueItem({leg: activePass, group: group.key, element: 'total', position: -1, index: -1}, group.total.valueType)" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>

    <div class="c-scores__table" v-if="useJoker">
      <div class="c-scores__table-body">
        <div class="c-scores__header-col">
          {{$t('joker')}}
        </div>
        <div class="c-scores__judge-col js-scores-judge">
          {{exercise.joker ? $t('exercise.joker') : $t('exercise.nojoker')}}
        </div>
        <div class="c-scores__total-col">
          <button class="c-button c-button-small c-button-tertiary" v-on:click.prevent="toggleJoker()">
            {{$t('panel.change')}}
          </button>
        </div>
      </div>
    </div>
    <div class="c-scores__table" v-if="isTeamEvent">
      <div class="c-scores__table-body">
        <div class="c-scores__header-col">
          {{$t('team')}}
        </div>
        <div class="c-scores__judge-col js-scores-judge">
          {{exercise.noTeam ? $t('exercise.noTeam') : $t('exercise.team')}}
        </div>
        <div class="c-scores__total-col">
          <button v-if="mode === 'admin'" class="c-button c-button-small c-button-tertiary" v-on:click.prevent="toggleTeam()">
            {{$t('panel.change')}}
          </button>
        </div>
      </div>
    </div>

    <div class="c-actions" v-if="mode !== 'super'">
      <div class="c-actions__buttons">
        <button v-on:click.prevent="openAllButton($event)" tabindex="-1"
                class="c-button c-button--small c-button--secondary">
          {{$t('panel.openAll')}}
        </button>
        <button v-on:click.prevent="clearScores($event)" tabindex="-1"
                class="c-button c-button--small c-button--secondary">
          {{$t('panel.clearScores')}}
        </button>
      </div>

      <div class="c-actions__buttons">
        <button v-on:click.prevent="cancel($event)" tabindex="-1"
                class="c-button c-button--small c-button--secondary">
          {{$t('cancel')}}
        </button>
        <button v-on:click.prevent="nextPassButtton($event)" v-if="activePass < configuration.passes"
                class="c-button c-button--small c-button--primary" tabindex="20000">
          {{$t('panel.nextPass')}}
        </button>
        <button v-on:click.prevent="finishButtton($event)" tabindex="20001"
                class="c-button c-button--small c-button--primary">
          {{$t('panel.finish')}}
        </button>
      </div>
    </div>

    <!--<div class="c-actions c-actions--right super" v-if="mode === 'super'">
      <button v-on:click.prevent="holdButtton($event)"
              class="c-button c-button--small c-button--primary c-actions__buttons">
        {{$t('panel.hold')}}
      </button>
      <button v-on:click.prevent="releaseButtton($event)"
              class="c-button c-button--small c-button--primary c-actions__buttons">
        {{$t('panel.release')}}
      </button>
    </div>-->
  </div>
</template>

<script>
  import clone from 'lodash/clone';
  import find from 'lodash/find';
  import findIndex from 'lodash/findIndex';
  import forEach from 'lodash/forEach';
  import range from 'lodash/range';

  import {round, ceil, pow} from 'mathjs';
  import numeral from 'numeral';

  import panelHandler from 'client/lib/panelHandler.js';
  import scoreHandler from 'lib/scoreHandler';

  const decimalCodes = [110, 188, 190]

  export default {
    name: "exercise-edit",
    components: {
      'scoreValue': require('./scoreValue.vue').default,
    },
    props: ['mode'],
    data: function () {
      return {
        values: [],
        valueLabels: [],
        currentLabel: undefined,
        calculationType: undefined,
        positions: [],
        editInput: '',
        panel: null,
        panelType: null,
        isTeamEvent: false,
        useJoker: false,
      }
    },
    computed: {
      configuration: function () {
        return this.$store.state.exercise.exerciseConfiguration
      },
      activePass: function () {
        return this.$store.state.exercise.pass
      },
      exercise: function () {
        return this.$store.state.exercise.exercise
      },
      inputFinished: function () {
        return this.$store.state.exercise.inputFinished
      },
      eventDiscipline: function() {
        return this.$store.state.eventDisciplines.items.find(i => i.id === this.exercise.eventDisciplineId)
      },
    },
    created: function() {
      // set panel
      if (this.$route.params.panelId) {
        this.panel = find(this.$store.state.panels.items, item => { return item.id === this.$route.params.panelId});
      } else {
        const session = find(this.$store.state.sessions.items, item => { return item.id === this.exercise.sessionId});
        const participation = find(this.$store.state.participations.items, item => {
          return item.id === this.exercise.participationId;
        });
        this.panel = panelHandler.getPanel(session, participation.categoryId, this.exercise.exerciseTypeId);
      }
      this.panelType = find(this.$store.state.eventDiscipline.panelTypes, item => {
        return item.id === this.panel.panelTypeId;
      });

      this.positions = panelHandler.calculatePositions(this.panel, this.panelType);
      const types = this.panelType.calculationTypes;
      this.calculationType = find(types, type => {
        return type.name === this.configuration.calculationType
      });

      // evaluate if this is a team competition:
      const round = find(this.$store.state.eventDiscipline.rounds, item => {
        return item.id === this.panel.roundId;
      });

      const roundRanking = find(round.rankings, item => {
        return item.enabled && (item.rankingType === 'TEAM-AA' || item.rankingType === 'TEAM-EVENTS')
      });

      this.isTeamEvent = !! roundRanking
      this.useJoker = this.eventDiscipline.useJokers;

      if (this.mode !== 'panel') {
        const _this = this;
        document.onkeyup = function (evt) {
          _this.keyPressed(evt);
        };

        let valueLabels = [];
        forEach(this.calculationType.scoreGroups, group => {
          if (group.roster === 'horizontal') {
            forEach(group.elements, element => {
              forEach(this.getGroupJudgePositions(group.key), position => {
                valueLabels.push(group.key + '|' + element.key + '|' + position + '|0');
              })
            });
          }
          else if (group.roster === 'vertical') {
            forEach(this.getGroupJudgePositions(group.key), position => {
              forEach(group.elements, element => {
                forEach(this.getIndexRange(element.count), v_index => {
                  valueLabels.push(group.key + '|' + element.key + '|' + position + '|' + v_index);
                });
              });
            });
          }
        });
        this.valueLabels = valueLabels;
        this.currentLabel = valueLabels[0];
      }
    },
    watch: {
      exercise: function () {
        this.updateScores()
      }
    },
    mounted: function(){
      let biggest = 0;
      [...document.querySelectorAll('.js-scores-judge')].map(x => x.getBoundingClientRect().width).reduce(function(prev, cur) {
        biggest = prev > cur ? prev : cur
        return biggest
      });

      [...document.querySelectorAll('.js-scores-judge')].map(x => x.style.width = `${biggest}px`)
    },
    methods: {
      getCalculationGroups: function () {
        return this.calculationType.scoreGroups.filter(g => ! g.refGroup)
      },
      elementIndexEnabled: function(index, valueType) {
        const countFilter = valueType.countFilter
        let enabled = true;
        if (countFilter) {
          const refElement = {
            group: countFilter.group,
            element: countFilter.element,
            position: this.panelType.chairPosition,
            index: 0,
            leg: this.activePass
          };
          const refValue = scoreHandler.exerciseGetValue(this.exercise, refElement);
          const key = countFilter.group + '.' + countFilter.element + '.max';

          if (countFilter.type === 'follow') {
            let max = -1;
            if (this.configuration[key]) {
              max = this.configuration[key]
            }
            if (refValue) {
              max = refValue.editValue
            }
            enabled = max > index
          }
          else if (countFilter.type === 'equal') {
            let value = countFilter.value
            if (this.configuration[key]) {
              value = this.configuration[key]
            }
            if (refValue) {
              enabled = refValue.editValue >= value
            }
          }
          else if (countFilter.type === 'param') {
            if (this.configuration[countFilter.name + '.min'] && index < this.configuration[countFilter.name + '.min']) {
              enabled = false
            }
            if (this.configuration[countFilter.name + '.max'] && index >= this.configuration[countFilter.name + '.max']) {
              enabled = false
            }
          }
        }

        return enabled
      },
      getValueItem: function (properties, valueType) {
        for (let i = 0; i < this.values.length; i++) {
          if (scoreHandler.equalProperties(properties, this.values[i].properties)) {
            return this.values[i];
          }
        }

        const scoreValue = scoreHandler.exerciseGetValue(this.exercise, properties);
        const newValue = {
          properties: properties,
          scoreValue: scoreValue,
          valueType: valueType
        };
        this.values.push(newValue);
        return newValue;
      },
      updateScores: function () {
        const _this = this;
        forEach(this.values, function (value) {
          value.scoreValue = scoreHandler.exerciseGetValue(_this.exercise, value.properties);
        });
      },
      cancel: function (event) {
        this.$store.dispatch("exercise.cancel", {nopanel: this.mode !== 'panel'}).then(() => {
          if (this.mode !== 'panel') {
            this.$emit('close', event)
          }
        });
      },
      finishButtton: function (event) {
        const nopanel = this.mode !== 'panel';
        this.$store.dispatch("exercise.finishPass", {nopanel: nopanel});
        if (this.mode !== 'panel') {
          this.$emit('close', event);
        }
      },
      nextPassButtton: function () {
        const nopanel = this.mode !== 'panel';
        this.$store.dispatch("exercise.finishPass", { nextPass: true, nopanel: nopanel }).then(() => {
          if (nopanel) {
            this.$navigation.goto({
              name: 'admin.event.discipline.dashboard.exercise',
              params: {
                panelId: this.$route.params.panelId,
                blockId: this.$route.params.blockId,
                rotation: this.$route.params.rotation,
                exerciseId: this.$route.params.exerciseId,
                pass: this.activePass
              }
            });
          }
        });
      },
      getGroupJudgePositions: function (groupKey) {
        return panelHandler.getGroupJudgePositions(groupKey, this.panelType)
      },
      getPositionLabel: function (position) {
        return this.positions[position].name
      },
      getIndexRange: function (count) {
        return range(count)
      },
      clearScores: function() {
        this.$modal.show({
          title: this.$t('panel.clearScores'),
          message: this.$t('panel.clearAlert.text'),
          onConfirm: () => this.clearAlertContinue()
        })
      },
      clearAlertContinue: function() {
        this.$store.dispatch("exercise.clearValues",
          {exerciseId: this.exercise.id, status: 'open', pass: this.activePass})
      },
      openAllButton: function() {
        this.$store.dispatch("exercise.openAllValues", {exerciseId: this.exercise.id})
      },
      getValueLabel: function(props) {
        return props.group + '|' + props.element + '|' + props.position + '|' + props.index
      },
      getValueObject: function(label) {
        return find(this.values, item => label === this.getValueLabel(item.properties))
      },
      selectValue: function (event) {
        if (this.mode === 'panel') {
          const value = event.value
          if (!value.scoreValue || !value.scoreValue.confirmed) {
            return
          }

          const title = this.getPositionLabel(value.scoreValue.position)
            + ' - ' + this.$t('score.' + value.scoreValue.group)
          this.$modal.show({
            title: title,
            message: this.$t('panel.allowUpdate.text'),
            onConfirm: () => {
              this.$store.dispatch("exercise.openValues",
                {exerciseId: this.exercise.id, scoreValue: value.scoreValue})
            }
          })
        }
        else {
          console.log(event)
          const selectedLabel = this.getValueLabel(event.value.properties);
          if (selectedLabel === this.currentLabel) {
            return;
          }
          this.saveInput()
          this.currentLabel = this.getValueLabel(event.value.properties)
        }
      },
      unselectValue: function(event) {
        if (this.mode !== 'panel') {
          console.log(event);
          const selectedLabel = this.getValueLabel(event.value.properties);
          if (selectedLabel !== this.currentLabel) {
            return;
          }
          this.saveInput();
          this.currentLabel = undefined;
        }
      },
      nextValue: function() {
        if (!this.currentLabel) {
          this.currentLabel = this.valueLabels[0];
          return;
        }

        this.saveInput();
        const index = findIndex(this.valueLabels, item =>{ return item === this.currentLabel; });
        if (index < 0 || index === this.valueLabels.length-1) {
          this.currentLabel = this.valueLabels[0];
        }
        else {
          this.currentLabel = this.valueLabels[index+1];
        }
      },
      prevValue: function() {
        if (!this.currentLabel) {
          this.currentLabel = this.valueLabels[this.valueLabels.length-1];
          return;
        }

        this.saveInput();
        const index = findIndex(this.valueLabels, item =>{ return item === this.currentLabel; });
        if (index < 0 || index === 0) {
          this.currentLabel = this.valueLabels[this.valueLabels.length-1];
        }
        else {
          this.currentLabel = this.valueLabels[index-1];
        }
      },
      saveInput: function() {
        if (this.currentLabel && this.editInput.length > 0) {
          let value = numeral(this.editInput).value();
          const valueObject = this.getValueObject(this.currentLabel);
          const valueType = valueObject.valueType;
          const factor = pow(10, valueType.precision);
          value = round(factor * value) / factor;
          let opts = clone(valueObject.properties);
          opts.leg = this.activePass;
          opts.value = value;
          opts.exerciseId = this.exercise.id;

          this.$store.dispatch('exercise.setScoreValue', opts);
          this.editInput = '';
        }
      },
      valuePressed: function (digit) {
        const value = this.getValueObject(this.currentLabel);
        const newInput = this.editInput + digit
        const val = numeral(newInput).value()
        let max = value.valueType.max
        const key = value.properties.group + '.' + value.properties.element + '.max'
        if (this.configuration[key]) {
          max = this.configuration[key]
        }
        if (val > max + 0.0000001) {
          return
        }
        let dotIndex = newInput.indexOf('.')
        if (dotIndex >= 0) {
          if (newInput.length - ++dotIndex > ceil(value.valueType.precision)) {
            return
          }
        }
        this.editInput = newInput
      },
      keyPressed: function(event) {
        if (this.currentLabel) {
          console.log('keyup', event);
          if (event.keyCode === 27) {
            this.editInput = '';
          } else if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
            this.valuePressed(event.key);
          }
          else if (event.keyCode === 8) {
            this.editInput = this.editInput.substr(0, this.editInput.length - 1);
          }
          else if (decimalCodes.includes(event.keyCode)) {
            if (this.editInput.indexOf('.') >= 0) {
              return;
            }
            this.editInput += '.';
          }

          event.preventDefault();
          return false;
        }
      },
      toggleTeam: function() {
        this.$store.dispatch("exercise.setNoTeam", {exerciseId: this.exercise.id, noTeam: ! this.exercise.noTeam});
      },
      toggleJoker: function() {
        this.$store.dispatch("exercise.setJoker", {exerciseId: this.exercise.id, joker: ! this.exercise.joker});
      },
    }
  };
</script>
