<template>
  <div class="c-exercise__result" :class="{'c-exercise_result--wide': discipline.report === 'wide'}">
    <template v-if="! exercise">
      <div class="status"></div>
    </template>
    <template v-else-if="exercise.status === 'dns'">
      <div class="status">{{$t('exercise.status.dns')}}</div>
    </template>
    <template v-else-if="exercise.status === 'scheduled' || ! exercise.summary || ! exercise.summary.passes[pass]">
      <div class="status"></div>
    </template>
    <template v-else>
      <div class="c-exercise__result-scores" v-if="exercise.summary">
        <div class="c-exercise__result-score" v-for="val in exercise.summary.passes[pass].values" :key="val.label">
          <span class="c-exercise__result-label">{{val.label}}</span>
          <span class="c-exercise__result-value">{{val.value}}</span>
        </div>
        <div class="c-exercise__result-score c-exercise__result-total">
          <span class="c-exercise__result-label">T</span>
          <span class="c-exercise__result-value">{{getTotalValue()}}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'blockExerciseResult',
  props: [
    'exercise', 'pass'
  ],
  computed: {
    discipline: function() {
      return this.$store.state.eventDiscipline.discipline;
    },
  },
  methods: {
    getTotalValue: function() {
      if (this.exercise.summary && this.exercise.summary.passes[this.pass]) {
        return this.exercise.summary.passes[this.pass].total;
      }
      return '';
    }
  }
};

</script>

<style scoped>
  /* TODO: include in css */
  .status {
    width: 50%;
    text-align: center;
  }
</style>
