<template>
  <div class="c-session-block__participant-names-wrapper">
    <template v-if="compact">
      <strong>{{getMemberName()}}</strong>,
      <span class="c-session-block__meta-info">{{ clubName }}</span>
    </template>
    <template v-else>
      <div class="name">{{getMemberName()}}</div>
      <div class="c-session-block__meta-info">{{ clubName }}</div>
    </template>
  </div>
</template>

<script>
  import base from './participantBase.vue'

  export default {
    extends: base,
    name: "participant-clubMember",
  };
</script>

<style scoped>
</style>
